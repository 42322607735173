<template>
  <div id="">         
    
  <section id="dashboard" class="mt-4">

    <div class="container">

        <div class="row mt-5">

          <SideNav v-bind:selected='2' />

            <div class="col-12 mt-sm-3 mt-md-0">
                <form id="payment_method" class="mt-2">
                  <div class="mb-2"></div>

                   <input v-model="title" id="ticket_price" type="text" name="ticket_price" placeholder="Title">
  

                   <div class="row mt-2 mb-2">
                       <div class="col-3">
                            <input readonly v-model="ticketAmount" id="ticket_amount" type="text" name="ticket_price" placeholder="Ticket Amount">
                       </div>
                       <div class="col-3">
                          <input v-model="startTime" type="time" id="start_time" name="start_time">
                        </div>
                       <div class="col-3">
                          <input v-model="endTime" type="time" id="end_time" name="end_time">
                       </div>
                        <div class="col-3">
                            <input v-model="startDate" type="date" id="start_date" name="start_date">
                        </div>
                       <div class="col-3">
                        <input v-model="endDate" type="date" id="end_date" name="end_date">
                       </div> 
                        <div class="col-3">
                            <input v-model="costPerTicket" id="ticket_price" type="text" name="ticket_price" placeholder="£">
                        </div>   
                        <div class="col-3">
                            <input v-model="maxAllowedTickets" type="number" placeholder="Max tickets per user">
                        </div>   
                   </div>

                    <textarea v-model="description" id="description" name="description" placeholder="Description" cols="30" rows="5">
                    </textarea>

                    <div class="row">
                        <div class="col-4">
                            <input v-on:change="onFileChange" type="file" id="img" name="img" accept="image/*">
                        </div>
                        <div class="col-8">
                            <input v-model="livestreamLink" id="ticket_price" type="text" name="ticket_price" placeholder="Facebook Link">
                        </div>
                    </div>

                    <input v-model="question" id="question" class="mb-2 mt-2" type="text" name="question" placeholder="Question">
                    <input v-model="answers" id="answers" class="mb-2" type="text" name="answers" placeholder="Possible Answers (including correct answer. Seperated by commas).">
                    <input v-model="correctAnswer" id="answer" type="text" name="answer" placeholder="Correct answer">

                    <button v-on:click="editCompetition" type="button" class="btn btn-custom mt-3 w-25">Edit Listing</button> 

                </form>
            </div>
        </div>
    </div>
  </section>

  <Footer/>
  </div>
</template>



<script>
import Api from '../../scripts/api'

import SideNav from '../../components/admin/SideNavigator'
import Footer from '../../components/global/Footer'

export default {
    props: {
      account:Object
    },
    components: {
        Footer,
        SideNav
    },
    data() {
      return {
        title: undefined,
        ticketAmount: undefined,
        startDate: undefined,
        startTime: undefined,
        endDate: undefined,
        endTime: undefined,
        costPerTicket: undefined,
        description: undefined,
        file: undefined,
        livestreamLink: undefined,
        question: undefined,
        answers: undefined,
        correctAnswer: undefined,
        maxAllowedTickets: undefined
      }
    },
    async created() {
      var result = await Api.admin.isAdmin();
      if(result.status != 200) {
        this.$router.push('/');
      }
      var competition = await Api.competition.getById(this.$route.params.id);

      if(competition.status == 200) {
        competition = await competition.json();
        console.log(competition);
        this.title = competition.title;
        this.ticketAmount = competition.maxTickets;
        this.startDate = new Date(competition.startDate);
        this.endDate = new Date(competition.endDate);
        this.costPerTicket = competition.ticketPrice;
        this.maxAllowedTickets = competition.personMaxTickets;
        this.description = competition.description;
        this.livestreamLink = competition.livestreamLink;
        this.question = competition.question.question;
        this.answers = competition.question.answers;
        this.correctAnswer = competition.question.correctAnswer;
        this.startTime = this.startDate.toISOString().split('T')[1].split(':')[0] + ':' + this.startDate.toISOString().split('T')[1].split(':')[1];
        this.endTime = this.endDate.toISOString().split('T')[1].split(':')[0] + ':' + this.endDate.toISOString().split('T')[1].split(':')[1];
        this.startDate = this.startDate.toISOString().split('T')[0]; 
        this.endDate = this.endDate.toISOString().split('T')[0];
        console.log('123');
      } else {
        this.$router.push('/');  
      }
    },
    methods: {
      onFileChange: function(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        this.file = files[0];
      },
      editCompetition: async function() {
        if(!this.title) return this.$toast.warning('Title cannot be empty.');
        if(!this.description) return this.$toast.warning('Please fill in the description.');
        if(!this.ticketAmount) return this.$toast.warning('Please fill in the ticket amount.');
        if(!this.startDate) return this.$toast.warning('Please select the start date.');
        if(!this.startTime) return this.$toast.warning('Please select the start time.');
        if(!this.endDate) return this.$toast.warning('Please select the end date.');
        if(!this.endTime) return this.$toast.warning('Please select the end time.');
        if(!this.costPerTicket) return this.$toast.warning('Please fill in the cost per ticket.');
        if(!this.question) return this.$toast.warning('Please specify a question.');
        if(!this.answers) return this.$toast.warning('Please specify the possible answers.');
        if(!this.correctAnswer) return this.$toast.warning('Please specify a correct answer.');
        if(!this.maxAllowedTickets) return this.$toast.warning('Please specify how many tickets each person can buy.');
        
        var result = await Api.admin.editCompetition(this.$route.params.id, this.title, this.ticketAmount, this.startDate, this.startTime, this.endDate, this.endTime, this.costPerTicket, this.description, this.file, this.livestreamLink, this.question, this.answers, this.correctAnswer, this.maxAllowedTickets);
        if(result.status == 200) {
          var data = await result.json();
          this.$router.push(data.redirect);
          this.$toast.success('Edited competition!');
        } else {
          this.$toast.warning('Error occurred: double check the entered data.');
        }
      }
    }
}
</script>
